import {ColorScheme, ColorSchemeProvider, MantineProvider} from "@mantine/core";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Main from "./components/layout/Main";
import Learn from "./routes/Learn";
import NotFound from "./routes/404";
import Playground, {PlaygroundLoader} from "./routes/Playground";
import {useLocalStorage, useMediaQuery} from "@mantine/hooks";

const router = createBrowserRouter(createRoutesFromElements(<>
    <Route path={"/"} element={<Main/>}>
        <Route index={true} element={<Learn/>}/>
        <Route path={"playground"} element={<Playground/>} loader={PlaygroundLoader}/>
    </Route>
    <Route path={"*"} element={<NotFound/>}/>
</>))

export default function App() {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: "color-scheme",
        defaultValue: useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light",
    })
    const toggleColorScheme = () => setColorScheme(colorScheme === "light" ? "dark" : "light")

    return (<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{
            colorScheme: colorScheme,
            primaryColor: "orange",
            lineHeight: 1.35,
            components: {
                Mark: {
                    defaultProps: {
                        color: "orange"
                    }
                },
                Title: {
                    defaultProps: {
                        mb: "sm"
                    }
                },
                List: {
                    defaultProps: {
                        my: "xs",
                    }
                },
                Table: {
                    defaultProps: {
                        my: "xs",
                    }
                },
                Container: {
                    defaultProps: {
                        size: "lg",
                    }
                },
            },
            globalStyles: (theme) => {
                const bg = theme.colorScheme === "light" ? theme.white : theme.colors.dark[7];
                const color = theme.colorScheme === "light" ? theme.black : theme.colors.gray[2];
                return {
                    ":root": {
                        "--background-color": theme.colorScheme === "light" ? theme.colors.white : theme.colors.dark[7],
                    },
                    // Fix horizontal scroll bar appearing in main view
                    ".mantine-AppShell-body main": {
                        maxWidth: "100%",
                    },
                    // React Flow Styles
                    // Controls
                    ".react-flow .react-flow__controls": {
                        borderRadius: theme.radius.sm,
                        boxShadow: theme.colorScheme === "light" ? "0 0 4px -1px black" : "0 0 0 1px rgba(255,255,255,.2)",
                    },
                    ".react-flow .react-flow__controls-button": {
                        "&:first-of-type": {
                            borderTopLeftRadius: theme.radius.sm,
                            borderTopRightRadius: theme.radius.sm,
                        },
                        "&:last-of-type": {
                            borderBottomLeftRadius: theme.radius.sm,
                            borderBottomRightRadius: theme.radius.sm,
                        },
                        backgroundColor: theme.colorScheme === "light" ? theme.fn.darken(theme.white, .01) : theme.colors.dark[5],
                        borderBottomStyle: "none",
                        "svg path": {
                            fill: theme.colorScheme === "light" ? theme.black : theme.colors.dark[0],
                        },
                        "&:hover": {
                            backgroundColor: theme.colorScheme === "light" ? theme.fn.darken(theme.white, .05) : theme.colors.dark[4],
                        },
                        "&:active svg": {
                            transform: "translateY(1px)"
                        },
                    },
                    // Node
                    ".react-flow .react-flow__node": {
                        display: "grid",
                        placeItems: "center",

                        backgroundColor: theme.colorScheme === "light"
                            ? theme.colors[theme.primaryColor][1]
                            : theme.fn.darken(theme.colors[theme.primaryColor][6], .8),
                        border: `1px solid ${theme.fn.variant({variant: "outline", color: theme.primaryColor}).border}`,
                        borderRadius: 25,

                        color,
                        fontSize: theme.fontSizes.lg,
                        fontWeight: "bold",
                    },
                    // Node handle
                    ".react-flow .react-flow__handle": {
                        width: 6,
                        height: 6,
                        borderRadius: 3,
                        backgroundColor: theme.fn.variant({variant: "outline", color: theme.primaryColor}).border,
                    },
                    // Edges/connections
                    ".react-flow .react-flow__edge-textbg": {
                        fill: bg,
                    },
                    ".react-flow .react-flow__edge-text": {
                        fontSize: theme.fontSizes.sm,
                        fill: color,
                    },
                    ".react-flow .react-flow__edge-path, .react-flow__arrowhead > polyline": {
                        stroke: color,
                    },
                };
            }
        }}>
            <RouterProvider router={router}/>
        </MantineProvider>
    </ColorSchemeProvider>)
}