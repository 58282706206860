import {Code, Container, Divider, List, Mark, Space, Text, Title} from "@mantine/core";
import Note from "../../components/Note";
import React from "react";
import NFAGraph from "../../components/NFAGraph";
import DFAGraph from "../../components/DFAGraph";
import {Hero} from "./Hero";
import {MinWidthTable} from "../../components/MinWidthTable";
import {useOutletContext} from "react-router-dom";
import {useScrollIntoView} from "../../hooks/use-scroll-into-view";
import {convertRegexToDFA, convertRegexToNFA} from "../../utils/regex";
import ResponsiveGroup from "../../components/ResponsiveGroup";

export default function Learn() {
    console.log(convertRegexToDFA("(a|b)*"))
    // Util functions
    function generateTableContents(data: [string, string, string, string | null, string[]][]): React.ReactNode[] {
        function joinItems(_items: string[]): React.ReactNode[] {
            const items = _items.map(item => <Code key={item}>{item}</Code>)
            const nodes: React.ReactNode[] = [items[0]]
            for (let i = 1; i < items.length; ++i) {
                nodes.push(", ")
                nodes.push(items[i])
            }
            return nodes
        }

        return data.map(item => (<tr key={item[0]}>
            <td>{joinItems([...item[0]])}</td>
            <td>{item[1]}</td>
            <td><Code>{item[2]}</Code></td>
            <td>{item[3] != null ? <Code>{item[3]}</Code> : <em>N/A</em>}</td>
            <td>{joinItems(item[4])}</td>
        </tr>))
    }

    function generateWalkThrough(data: [string | number, string | null, React.ReactNode, string?][]) {
        return data.map((item, index) => (<tr key={index}>
            <td><strong>{item[0]}</strong>/{item[1] ?? <em>(none)</em>}</td>
            <td>{item[2]}</td>
            <td>{item[3] ? <Code>{item[3]}</Code> : <em>(empty)</em>}</td>
        </tr>))
    }

    const {scrollIntoView, targetRef} = useScrollIntoView<HTMLHeadingElement>({offset: useOutletContext<number>() + 6})

    return (<>
        <Hero scrollIntoView={scrollIntoView.bind(null, {alignment: "start"})}/>
        <Container>
            <Title order={2} ref={targetRef}>Regular Expressions</Title>
            <Text>A regular expression is be used to match text. A minimal notation regular expression is composed of 0 or more characters, which can
                be grouped with parentheses, and the <Code>*</Code> (matches multiple times) and <Code>|</Code> (OR operator) characters.
                Adjacent characters (also known as concatenated characters) are considered to be in a AND operation, e.g. <Code>ab</Code> means
                <Code>a</Code> and <Code>b</Code>: "ab" would match.</Text>
            <Space h={"xs"}/>
            <Text>Example: <Code>(abc)*(d|e)</Code><br/>
                This translates to "abc" 0 or more times, followed by a "d" or "e". For example, the strings "abcd" and "e" would match this
                regex.</Text>
            <Note>The special symbol <Code>ε</Code> - <Mark>Epsilon</Mark> represents the 0-length string (i.e. nothing - ""). This is a theoretical
                term - it cannot be used in a programming language - use the empty string "" instead.</Note>
            <Text>In the minimal notation, only the <Code>()*|</Code> special characters are defined. However, for easier usability, practically all
                RegEx implementations define additional characters. The most important special characters are described below:</Text>
            <MinWidthTable>
                <thead>
                <tr>
                    <th>Character(s)</th>
                    <th>Usage</th>
                    <th>Syntax</th>
                    <th>Minimal Notation Equivalent</th>
                    <th>Matches</th>
                </tr>
                </thead>
                <tbody>{generateTableContents([
                    ["()", "Contains a sub-regex, used to apply modifiers (*, + etc.) to a portion of the regex", "(abc)", null, ["abc"]],
                    ["|", "Separates multiple sub-regexes, matches exactly one (any) of them", "(a|b|c)", null, ["a", "b", "c"]],
                    ["*", "Matches previous entity 0 or more times", "a*", null, ["ε", "a", "aa", "..."]],
                    ["+", "Matches previous entity 1 or more times", "a+", "(aa*)", ["a", "aa", "..."]],
                    ["?", "Matches previous entity 0 or 1 times", "a?", "(a|ε)", ["ε", "a"]],
                    ["[]", "Creates a character class - matches any character inside the class.", "[abc]", "(a|b|c)", ["a", "b", "c"]],
                ])}</tbody>
            </MinWidthTable>

            <Space h={"md"}/>

            <Title order={2}>But how do they work?</Title>
            <Text>Suppose we have a regular expression, such as the example above: <Code>(abc)*(d|e)</Code>. How would a computer algorithm implement
                it, i.e. determine if string matches said expression? The answer is using an <strong>NFA</strong> (nondeterministic finite automata).
                In short, it's a simple algorithms that switches <em>states</em> using <em>transitions</em>.</Text>
            <Text>Let's take a simpler example: <Code>ab*c</Code>. This regular expression matches an 'a', followed by 0 or more 'b's, followed by a
                'c': "ac", "abc", "abbc" and so on. A simple NFA that would match this expression would be:</Text>
            <NFAGraph nfa={[
                {id: 0},
                {id: 1, char: 'a'},
                {id: 2, char: 'b', transitionTo: 1},
                {id: 3, char: 'c'},
            ]}/>
            <Text>Let's say we are trying to match "abbc". In this case, the automata goes as follows:</Text>
            <MinWidthTable>
                <thead>
                <tr>
                    <th>Current state/character</th>
                    <th>Explanation</th>
                    <th>Remaining string</th>
                </tr>
                </thead>
                <tbody>
                {generateWalkThrough([
                    [0, 'a', (<div>
                        Begin in the <em>initial</em> (<strong>0</strong>) state - marked with a <Code>&gt;</Code> in front of it. We can leave this
                        state using a <em>transition</em> - either by reading a character, or arbitrarily (only for epsilon transitions).
                    </div>), "abbc"],
                    [0, 'a', (<div>Now, we read the first character of the string - 'a'. There is a transition for this character, so we move to
                        state <strong>1</strong>. Note that, if state <strong>0</strong> didn't have a transition for 'a', we wouldn't be able to
                        continue. In this case, we would've said that the string <em>does not match</em> the regular expression.
                    </div>), "bbc"],
                    [1, 'b', (<div>
                        In this state, we can read the next character of our string - 'b', and move according to the transition to
                        state <strong>2</strong>.
                    </div>), "bc"],
                    [2, 'b', (<div>
                        In this state, there are 2 transitions: an epsilon transition, that we can take "for free" (without reading a character), or
                        the 'c' character transition. However, the next character is 'b', so we cannot take the latter transition. We are forced to
                        take the epsilon transition, going back to state <strong>1</strong>.
                    </div>), "bc"],
                    [1, 'b', (<div>
                        Back in state <strong>1</strong>, we now have a transition matching the next character of the string - 'b'. We take this
                        transition, going to state <strong>2</strong> again.
                    </div>), "c"],
                    [2, 'c', (<div>
                        Here, we can go 2 ways: take the epsilon transition back to 1, where we'd get stuck (in state <strong>1</strong> there is no
                        transition we can take), or we can the the 'c' character transition to state <strong>3</strong> - this is what we do.
                    </div>)],
                    [3, null, (<div>
                        Finally, we reach state <strong>3</strong>. Here, there are no more transitions, but because the string we were matching was
                        depleted and because this state is a <em>final</em> state (denoted by the double circle), we say the automata has finished
                        successfully, i.e. the string we were testing ("abbc") matches the regular expression "ab*c".
                    </div>)],
                ])}
                </tbody>
            </MinWidthTable>
            <Note>From now on, we will refer to a RegEx using the JavaScript notation - anything between 2 forward slashes (/) is a regex,
                e.g. <strong>/ab*c/</strong>. Quotation marks are used for <strong>"strings"</strong>, which we will match against the regex.</Note>

            <Space h={"md"}/>

            <Title order={2}>Converting any RegEx to an NFA</Title>
            <Text>There is a simple algorithm to convert any RegEx to an NFA. We will start very simple: the single character regular expression.
                Let's take a look at <strong>/a/</strong> and, why not, <strong>/b/</strong> as well:</Text>
            <ResponsiveGroup grow cutoff={"xs"} my={"sm"}>
                <NFAGraph height={100} nfa={convertRegexToNFA("a")}/>
                <NFAGraph height={100} nfa={convertRegexToNFA("b")}/>
            </ResponsiveGroup>
            <Text>These 2 are very similar. In fact, here we can deduce our first formula - <Mark>concatenation</Mark>.
                To form <strong>/ab/</strong> from <strong>/a/</strong> and <strong>/b/</strong>, all we have to do is connect
                the <em>final state</em> of the first NFA to the <em>initial state</em> of the second NFA using an epsilon-transition:</Text>
            <NFAGraph height={100} my={"sm"} nfa={convertRegexToNFA("ab")}/>
            <Text>Like this, we can create an NFA for a RegEx that matches a specific string. Next up: <Mark>branching</Mark>. Let's say we want to
                match "a" <em>or</em> "b": <strong>/a|b/</strong>. In what way should we connect the 2 simple NFAs that we started from? The answer
                is this:</Text>
            <NFAGraph height={200} my={"sm"} nfa={convertRegexToNFA("a|b")}/>
            <Text>We first need an <em>initial state</em>, here <strong>1</strong>. From this, using epsilon-transitions, we can go either way: to
                match "a", on the upper branch, or "b", on the lower branch. Afterwards, the branches <em>merge</em> back together, to allow
                concatenation with additional characters. For example - <strong>/(a|b)c/</strong>:</Text>
            <Note showNote={false}>Notice the parentheses around 'a|b' - this is because <strong>/a|bc/</strong> means "a" or "bc", not
                "a" or "b" followed by "c".</Note>
            <NFAGraph height={200} my={"sm"} nfa={convertRegexToNFA("(a|b)c")}/>
            <Text>This NFA matches either "ac" or "bc", and we obtain it the same way we obtained the one for <strong>/ab/</strong> earlier.
                Notice that states <strong>1-6</strong> are exactly the NFA for <strong>/a|b/</strong>, and states <strong>7-8</strong> are the NFA
                for <strong>/c/</strong>. These are then contatenaced with an epsilon-transition from <strong>6</strong> to <strong>7</strong>.</Text>
            <Space h={"xs"}/>
            <Text>At last, we reach the <Mark>star operator *</Mark>. Let's first take a look at the simplest example - <strong>/a*/</strong>:</Text>
            <NFAGraph height={150} my={"sm"} nfa={convertRegexToNFA("a*")}/>
            <Text><strong>a*</strong> means 'a' 0 or more times. The '0 times' part is achieved using an epsilon-transition directly from
                the <em>initial state</em> to the <em>final state</em>. This allows us to get to the final state, should the string we're matching be
                empty, which is exactly what we want. If it's not, however, we will match the character 'a' and go to state <strong>3</strong>.
                Here, we have a choice: go to the <em>final state</em>, which is a successful match, if the string was depleted, or jump back to
                state <strong>2</strong>, where we can match 'a' again. We can repeat this cycle as much as we want to match any mount of 'a'
                characters, and stop whenever we want.</Text>
            <Space h={"xs"}/>
            <Text>The important part here is the structure: here, states <strong>2-3</strong> could be replaced by any other NFA we want, like the one
                for <strong>/a|b/</strong>, for example, to match it any amount of times. What "belongs" to the <em>star operator</em> are:
                the states <strong>1</strong> and <strong>4</strong>, the epsilon-transition from <strong>1</strong> to <strong>4</strong>, and the
                epsilon-transition back from <strong>3</strong> to <strong>2</strong>. To conclude, here is the NFA
                for <strong>/(a|b)*/</strong>:</Text>
            <NFAGraph height={280} my={"sm"} nfa={convertRegexToNFA("(a|b)*")}/>
            <Space h={"xl"}/>
            <Text>
                As you may have noticed, there's a problem with this approach: we don't always know which state we should go to (e.g. in the next to
                last step, there's 2 available transitions: the epsilon transition to state <strong>1</strong> and the 'c' character transition to
                state <strong>3</strong>). For a simple example like the one above, it's easy to see that taking the epsilon transition leads to a
                fail (as we end up with nowhere to go in a non-final state - <strong>1</strong> - but we still have the 'c' character left), but in
                more complex examples, where at every step there's multiple available transitions, it becomes very difficult to follow the
                automata.
                <Space h={"xs"}/>
                This is because the automata above is Nondeterministic, which means at every step (in our case, in every state), in order to solve it,
                execution is split into multiple branches (which execute in parallel), each branch taking an available transition. In other words, in
                theory, if we have multiple transitions available, we'd have to follow them all simultaneously, which is impossible, even for a
                computer: if a CPU has 8 cores, it can follow up to 8 transitions at once, but even then it will result to trying them out
                sequentially, which is our initial problem.
                <Space h={"xs"}/>
                So, how to solve this problem?
            </Text>

            <Space h={"md"}/>

            <Title order={2}>Converting an NFA to a DFA</Title>
            <Text>
                An NFA is, by definition, non-deterministic (multiple transitions available at once, in our case). However, we want to convert it to a
                DFA, which a bit like an NFA at first sight, with one big exception: <em>in each state, there is only 1 transition available at a
                time</em>. The algorithm for converting an NFA to a DFA is as follows:
            </Text>
            <List>
                <List.Item><Text>Each state in our new DFA will be composed of multiple states in the NFA graph. More precisely, it will be composed
                    of all <em>reachable</em> states in the NFA. To understand this, let's take a look at the NFA for <strong>/ab/</strong>, as well
                    as the result of converting it to a DFA:</Text></List.Item>
            </List>
            <ResponsiveGroup grow cutoff={"sm"} my={"sm"} spacingVertical={"xs"}>
                <NFAGraph nfa={convertRegexToNFA("ab")}/>
                <Divider orientation={"vertical"}/>
                <DFAGraph dfa={convertRegexToDFA("ab")}/>
            </ResponsiveGroup>
            <List>
                <List.Item><Text>Initially, we start in state <strong>1</strong>; no other state are <em>reachable</em> via
                    epsilon-transitions.</Text></List.Item>
                <List.Item><Text>Next, we look at available characters, and we go through them 1 by 1. The next DFA state will be composed of all NFA
                    states <em>reachable</em> by the chosen character transition, followed by any amount of epsilon-transitions. In our example above,
                    for the 'a' character transition, we can reach state <strong>2</strong>, as well as state <strong>3</strong> (using an
                    epsilon-transition). This means that the next DFA transition is composed of these 2 states - so we will label
                    it <strong>2,3</strong>.</Text></List.Item>
                <List.Item><Text>Next, we look at all nodes reachable using the 'c' character transitions, in the NFA graph. Here, it's only
                    state <strong>4</strong>, as there are no epsilon-transition from this state, so the conversion ends.</Text></List.Item>
            </List>
            <Text>Next, let's take a look at a more complicated example: <strong>/(a|b)*/</strong>.</Text>
            <ResponsiveGroup grow my={"sm"} spacingVertical={"xs"}>
                <NFAGraph nfa={convertRegexToNFA("(a|b)*")}/>
                <Divider orientation={"vertical"}/>
                <DFAGraph dfa={convertRegexToDFA("(a|b)*")}/>
            </ResponsiveGroup>
            <Text>The algorithm is the same:</Text>
            <List>
                <List.Item><Text>Start from NFA state <strong>1</strong>. All NFA states reachable via epsilon-transitions are 2 and 8, then, from
                    state <strong>2</strong>, <strong>3</strong> and <strong>5</strong> are also reachable. So, the first DFA node
                    is <strong>1,2,3,5,8</strong>.</Text></List.Item>
                <List.Item><Text>Now, we start from NFA states <strong>1,2,3,5,8</strong>. We pick an available character transition: 'a'. There is
                    just 1 state reachable via 'a' character transitions: <strong>4</strong>. We also look at following epsilon-transitions, in our
                    case, from state <strong>4</strong>: we reach <strong>7,8,2,3,5</strong>. So, the next DFA state will be composed of all of these
                    states, like so: <strong>2,3,4,5,7,8</strong>. Because we picked the character 'a' and started from <strong>1,2,3,5,8</strong>,
                    this means we now have our first DFA transitions: from <strong>1,2,3,5,8</strong> to <strong>2,3,4,5,7,8</strong>, character 'a'.
                </Text></List.Item>
            </List>
            <Note>What a DFA state really means is: <em>at this point, we can be in any of these NFA states</em>. In the example above, initially, we
                start in state <strong>1</strong>, but we can move to <strong>2, 3, 5</strong> or <strong>8</strong> via epsilon-transitions, as
                we like. From this state, if we want to take an 'a' character transition, we can do so from any of the before-mentioned states, as
                we could be in any of them. After taking the 'a' character transition, we could be in state <strong>4</strong>, but
                also <strong>2, 3, 5, 7</strong> or <strong>8</strong>, depending on what epsilon-transitions we want to take.</Note>
            <Text>Similarly, we can figure the last DFA transition - <strong>2,3,5,6,7,8</strong>, as well as all transitions between these DFA
                states. To conclude, the algorithm steps are:</Text>
            <List type={"ordered"}>
                <List.Item><Text>The initial DFA state is composed of the initial NFA state and all states available via epsilon transitions.
                </Text></List.Item>
                <List.Item><Text>From a DFA state composed of a list <strong>L</strong> of NFA states: for every available character transition,
                    create a list <strong>P</strong> of NFA states reachable from any state in the list <strong>L</strong>, via the character
                    transition. Also create a list <strong>Q</strong> of NFA states reachable from any state in list <strong>P</strong> via
                    epsilon-transitions. A new DFA state is composed of all NFA states in <strong>P</strong> and <strong>Q</strong>, and there is a
                    transition from the starting DFA state to the new DFA state of the chosen character transition. Should the DFA state already exist
                    (there already is a DFA state composed of exactly the NFA state in <strong>P</strong> and <strong>Q</strong>), no new DFA state
                    is created, but the new transition should still be considered.</Text></List.Item>
                <List.Item><Text>Repeat <strong>(2)</strong> until no new DFA states can be generated.</Text></List.Item>
            </List>
        </Container>
    </>)
}