import {createStyles, Container, Group, Text, Anchor} from '@mantine/core';
import { MantineLogo } from '@mantine/ds';
import reactFlowLogo from '../../img/react-flow.svg'

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: 120,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },
    reactFlowLogo: {
        div: {
            display: "flex",
            padding: 4,
            borderRadius: theme.radius.md,
            backgroundColor: theme.colorScheme === "light" ? "transparent" : theme.colors.dark[2],
            img: {
                width: 20,
                height: 20,
            },
        },
        fontWeight: "bold",
        color: theme.colorScheme === "light" ? theme.black : theme.white,
    },
    anchor: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    info: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
        },
    },
}));

export function FooterSimple() {
    const { classes } = useStyles();

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div>
                    <Group spacing={"sm"}>
                        Made with
                        <Anchor className={classes.anchor} href={"https://mantine.dev"} target={"_blank"}>
                            <MantineLogo size={22} style={{transform: "translateY(4px)"}}/>
                        </Anchor>
                        <Anchor className={classes.anchor} href={"https://reactflow.dev/"} target={"_blank"}>
                            <Group className={classes.reactFlowLogo} spacing={6}>
                                <div><img src={reactFlowLogo} alt={"logo"}/></div>
                                React Flow
                            </Group>
                        </Anchor>
                    </Group>
                </div>

                <Group className={classes.info}><Text italic>by Matei Trandafir</Text></Group>
            </Container>
        </footer>
    );
}