import React from "react";
import {createStyles, MantineNumberSize, ScrollArea, Table, TableProps} from "@mantine/core";

const useStyles = createStyles((theme, {minWidth}: MinWidthTableProps) => ({
    table: {
        minWidth: theme.fn.size({size: minWidth ?? "sm", sizes: theme.breakpoints}),
    },
}))

export interface MinWidthTableProps extends TableProps {
    minWidth?: MantineNumberSize
}

export const MinWidthTable = React.forwardRef<HTMLTableElement, MinWidthTableProps>(
    ({minWidth, children, ...rest}, ref) => {
        const {classes} = useStyles({minWidth})
        return (<ScrollArea>
            <Table className={classes.table} ref={ref} {...rest}>
                {children}
            </Table>
        </ScrollArea>)
    }
)