import {Box, BoxProps, createStyles, Text, useMantineTheme} from "@mantine/core";
import {
    Background,
    Controls,
    MarkerType,
    ReactFlow,
    ReactFlowInstance,
    ReactFlowProps,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow,
    useUpdateNodeInternals
} from "reactflow";
import React, {forwardRef, memo, useCallback, useEffect, useRef} from "react";
import 'reactflow/dist/base.css'
import {useId, useMergedRef} from "@mantine/hooks";

const useStyles = createStyles((theme, {height = 250}: { height?: number }) => {
    const variant = theme.fn.variant({variant: "outline", color: theme.primaryColor})
    return {
        graph: {
            height,
            ".react-flow__edge-textwrapper": {
                pointerEvents: "none",
            },
            ".input-node": {
                "&::before": {
                    content: '">"',
                    display: "block",
                    pointerEvents: "none",

                    position: "absolute",
                    fontSize: 24,

                    top: 5,
                    left: -21,
                    color: theme.fn.darken(variant.border!, .1),
                },
            },
            ".output-node": {
                "&::after": {
                    content: '""',
                    pointerEvents: "none",

                    position: "absolute",
                    inset: 2,

                    border: `solid 1px ${variant.border}`,
                    borderRadius: 27,
                },
            },
        },
    };
})

export interface BaseGraphProps extends BoxProps, React.ComponentPropsWithoutRef<"div"> {
    title?: string,
    height?: number
    withControls?: boolean
    reactFlowProps?: Omit<ReactFlowProps, "nodes" | "edges" | "nodeTypes" | "edgeTypes">,
    setReactFlow?: React.Dispatch<React.SetStateAction<ReactFlowInstance | null>>,
}

const defaultEdgeOptions = {
    markerEnd: {
        type: MarkerType.Arrow,
        width: 20,
        height: 20,
    },
}

function Graph(
    {
        title, withControls = false, reactFlowProps, setReactFlow,
        nodes, edges, fitView, nodesDraggable = false, children, nodeTypes, edgeTypes, boxRef
    }: InternalProps
) {
    const [nodesState, setNodes, onNodesChange] = useNodesState(nodes)
    const [edgesState, setEdges] = useEdgesState(edges)

    const reactFlow = useReactFlow()
    useEffect(() => {
        if (setReactFlow) setReactFlow(reactFlow)
    }, [setReactFlow, reactFlow])

    const flowFitView = useCallback(() => {
        if (fitView) fitView(reactFlow)
        else reactFlow.fitView()
    }, [fitView, reactFlow])

    const update = useUpdateNodeInternals()
    useEffect(() => {
        setNodes(nodes)
        setEdges(edges)
        // Necessary as new nodes will have same IDs as old nodes
        setTimeout(() => {
            for (const node of nodes) update(node.id)
            setTimeout(flowFitView, 10);
        }, 10)

        const box = boxRef.current
        if (!box) return

        const observer = new ResizeObserver(flowFitView)
        observer.observe(box)
        return () => observer.disconnect()
    }, [nodes, edges, setNodes, setEdges, boxRef, flowFitView, update])

    const theme = useMantineTheme()
    return (<ReactFlow id={useId()} style={withControls ? undefined : {pointerEvents: "none"}} proOptions={{hideAttribution: true}}
                       nodesDraggable={nodesDraggable} nodesConnectable={false} elementsSelectable={false} edgesFocusable={false}
                       nodes={nodesState} edges={edgesState} nodeTypes={nodeTypes} edgeTypes={edgeTypes} defaultEdgeOptions={defaultEdgeOptions}
                       onNodesChange={onNodesChange} onInit={flowFitView} {...reactFlowProps}>
        <Background color={theme.colorScheme === "light" ? theme.colors.dark[0] : theme.colors.dark[3]}/>
        {withControls && <Controls showInteractive={false} onFitView={flowFitView}/>}
        {title && <Text size={"xl"} pl={"md"} pt={"md"} pr="xs" pb={"xs"} sx={{
            display: "inline-block",
            position: "absolute",
            zIndex: 4,
            backgroundColor: "var(--background-color, transparent)",
            borderRadius: `0 0 ${theme.radius.md}px 0`,
        }}>{title}</Text>}
        {children}
    </ReactFlow>)
}

export interface GraphProps extends BaseGraphProps,
    Required<Pick<ReactFlowProps, "nodes" | "edges">>,
    Pick<ReactFlowProps, "nodeTypes" | "edgeTypes"> {

    fitView?: (flow: ReactFlowInstance) => void,
    nodesDraggable?: boolean,
}

interface InternalProps extends GraphProps {
    boxRef: React.RefObject<HTMLDivElement>
    forwardedRef?: React.ForwardedRef<HTMLDivElement>
}

const GraphWrapper = memo<InternalProps>((
    {
        title, height, withControls, reactFlowProps, className,
        boxRef, forwardedRef, setReactFlow, children,
        nodes, edges, fitView, nodesDraggable, nodeTypes, edgeTypes, ...rest
    }) => {
    const {classes, cx} = useStyles({height})
    const mergedRef = useMergedRef(boxRef, forwardedRef ?? null)
    return (<Box className={cx(classes.graph, className)} ref={mergedRef} {...rest}>
        <ReactFlowProvider>
            <Graph {...{
                title, withControls, reactFlowProps, nodes, edges, fitView, nodesDraggable, children, nodeTypes, edgeTypes, boxRef, setReactFlow
            }}/>
        </ReactFlowProvider>
    </Box>)
})

export default forwardRef<HTMLDivElement, GraphProps>((props, ref) => {
    return <GraphWrapper boxRef={useRef(null)} forwardedRef={ref} {...props}/>
})