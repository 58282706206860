import {Center, createStyles, Divider, Group, Paper, Text, useMantineTheme} from "@mantine/core";
import {Link, useLocation} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    container: {
        boxSizing: "border-box",
        height: "100vh",
        paddingBlockEnd: "10vh",
        backgroundColor: theme.fn.rgba(theme.fn.darken(theme.fn.primaryColor(), 0.25), .9)
    },
    paper: {
        padding: theme.spacing.xl,
        marginInline: theme.spacing.xl,
        [theme.fn.smallerThan("sm")]: {
            padding: theme.spacing.md,
        },
    },
    group: {
        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },
    divider: {
        borderRadius: 1,
        alignSelf: "stretch",
    },
    pathname: {
        width: 0,
        minWidth: "100%",
        overflowWrap: "anywhere",
        marginBlock: theme.spacing.xs,
    },
}))

export default function NotFound() {
    const {classes} = useStyles()
    const theme = useMantineTheme()
    const color = theme.fn.variant({variant: "outline", color: theme.primaryColor}).color!;
    const smallScreen = useMediaQuery(theme.fn.smallerThan("sm").replace("@media", ""))
    const location = useLocation()

    return (<Center className={classes.container}>
        <Paper className={classes.paper} shadow={"md"} radius={"lg"}>
            <Group className={classes.group}>
                <Text size={100} weight={"bold"} color={theme.fn.rgba(color, .9)}>404</Text>
                <Divider className={classes.divider} orientation={smallScreen ? "horizontal" : "vertical"} size={"sm"} color={color}/>
                <div>
                    <Text>The requested page was not found:</Text>
                    <Text className={classes.pathname} italic>{location.pathname}</Text>
                    <Text component={Link} to={"/"} variant={"link"}>😕 Click here to go back home</Text>
                </div>
            </Group>
        </Paper>
    </Center>)
}