import React from "react";
import {createStyles, Paper, PaperProps, Text} from "@mantine/core"

const useStyles = createStyles(theme => ({
    paper: {
        backgroundColor: theme.colorScheme === "light" ? theme.colors.gray[0] : theme.colors.dark[5],
    },
}))

export interface NoteProps extends PaperProps {
    showNote?: boolean
}

export default React.forwardRef<HTMLDivElement, NoteProps>((
    {
        showNote = true, p = "xs", my = "xs", className,
        withBorder = true, children, ...rest
    }, ref) =>
{
    const {classes, cx} = useStyles()

    return (<Paper ref={ref} withBorder={withBorder} shadow={"xs"} p={p} my={my} className={cx(classes.paper, className)} {...rest}>
        {showNote && <Text span weight={"bold"}>Note: </Text>}
        <Text span italic>{children}</Text>
    </Paper>)
}
)