import {createStyles, Box, GroupProps, MantineNumberSize, GroupPosition} from "@mantine/core";
import React, {forwardRef} from "react";
type StyleParams = Pick<ResponsiveGroupProps, "cutoff" | "position" | "noWrap" | "grow" | "spacingHorizontal" | "spacingVertical" | "align">

const positions: {[k in GroupPosition]: React.CSSProperties["justifyContent"]} = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    apart: "space-between",
}

const useStyles = createStyles((theme, {
    cutoff = "md", position = "left", noWrap = false, grow, spacingHorizontal = "sm", spacingVertical = "sm", align = "stretch"
}: StyleParams) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: align,
        flexWrap: noWrap ? "nowrap" : "wrap",
        justifyContent: positions[position],
        gap: theme.fn.size({size: spacingHorizontal, sizes: theme.spacing}),

        [theme.fn.smallerThan(cutoff)]: {
            flexDirection: "column",
            gap: theme.fn.size({size: spacingVertical, sizes: theme.spacing}),
        },

        ...(grow ? {"& > :not(.mantine-Divider-root)": {
            flexGrow: 1,
        }} : null),
    },
}))

export interface ResponsiveGroupProps extends Omit<GroupProps, "spacing"> {
    cutoff?: MantineNumberSize
    spacingHorizontal?: MantineNumberSize
    spacingVertical?: MantineNumberSize
}

export default forwardRef<HTMLDivElement, ResponsiveGroupProps>((
    {cutoff, position, noWrap, grow, spacingHorizontal, spacingVertical, align, className, children, ...rest}: ResponsiveGroupProps, ref
) => {
    const {classes, cx} = useStyles({cutoff, position, noWrap, grow, spacingHorizontal, spacingVertical, align})
    return <Box ref={ref} className={cx(classes.root, className)} {...rest}>{children}</Box>
})