import {Anchor, Box, Center, Container, createStyles, Group, Header, Menu, Text, useMantineTheme} from '@mantine/core';
import {IconChevronDown} from '@tabler/icons';
import {SwitchToggle} from "../SwitchToggle";
import {Link, NavLink} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";

import regexLogo from "../../img/regex-logo.png"
import React, {useEffect} from "react";

type StylesProps = {
    smallScreen: boolean,
    height: number,
}

const useStyles = createStyles((theme, {smallScreen, height}: StylesProps) => {
    const variant = theme.fn.variant({variant: 'filled', color: theme.primaryColor}).background!
    const headerColor = theme.fn.darken(variant, theme.colorScheme === "dark" ? .2 : .05)

    return {
        header: {
            backgroundColor: headerColor,
            borderBottom: 0,
        },

        inner: {
            boxSizing: "border-box",
            height: height,
            display: 'flex',
            flexDirection: smallScreen ? "column" : "row",
            justifyContent: 'space-between',
            alignItems: smallScreen ? "auto" : "center",
            paddingBlock: smallScreen ? 10 : 0,
        },

        link: {
            display: 'block',
            lineHeight: 1,
            padding: '8px 12px',
            borderRadius: theme.radius.sm,
            textDecoration: 'none',
            color: theme.white,
            fontSize: theme.fontSizes.sm,
            fontWeight: 500,

            '&.active': {
                fontWeight: "bold",
            },

            '&:hover': {
                backgroundColor: theme.fn.lighten(headerColor, 0.1),
            },
        },

        switchToggle: {
            marginTop: -10,
            marginLeft: smallScreen ? "auto" : theme.spacing.sm,
        },

        linkLabel: {
            marginRight: 5,
        },
    }
});

interface HeaderSearchProps {
    links: { link: string; label: string; links?: { link: string; label: string }[] }[]
    setHeaderHeight?: (height: number) => any
}

export function HeaderMenuColored({links, setHeaderHeight}: HeaderSearchProps) {
    const theme = useMantineTheme()
    const smallScreen = useMediaQuery(theme.fn.smallerThan(600).replace("@media", ""))
    const height = smallScreen ? 92 : 56
    useEffect(() => {
        if (setHeaderHeight) setHeaderHeight(height)
    })
    const {classes} = useStyles({smallScreen, height});

    const items = links.map((link) => {
        const menuItems = link.links?.map((item) => (
            <Menu.Item key={item.link}>{item.label}</Menu.Item>
        ));

        if (menuItems) {
            return (
                <Menu key={link.label} trigger={"hover"} exitTransitionDuration={0}>
                    <Menu.Target>
                        <NavLink to={link.link} className={classes.link} end>
                            <Center>
                                <span className={classes.linkLabel}>{link.label}</span>
                                <IconChevronDown size={12} stroke={1.5}/>
                            </Center>
                        </NavLink>
                    </Menu.Target>
                    <Menu.Dropdown>{menuItems}</Menu.Dropdown>
                </Menu>
            );
        }

        return (
            <NavLink key={link.label} to={link.link} className={classes.link} end>
                {link.label}
            </NavLink>
        );
    });

    return (
        <Header height={height} className={classes.header} mb={120}>
            <Container>
                <div className={classes.inner}>
                        <Anchor component={Link} to={"/"} underline={false}>
                            <Box component={"img"} height={22} src={regexLogo} alt={"RegEx"} sx={{
                                //filter: (theme.colorScheme === "dark" ? "contrast(115%) brightness(115%)" : "none"),
                            }}/>
                            <Text span size={20} italic color={"white"}> explained</Text>
                        </Anchor>
                    <Group spacing={5}>
                        {items}
                        <div className={classes.switchToggle}><SwitchToggle/></div>
                    </Group>
                </div>
            </Container>
        </Header>
    );
}