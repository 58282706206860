import {AppShell, Space} from "@mantine/core"
import {Outlet} from "react-router-dom"
import {HeaderMenuColored} from "./HeaderMenuColored"
import {FooterSimple} from "./FooterSimple"
import {useState} from "react";

export default function Main() {
    const [headerHeight, setHeaderHeight] = useState(0)

    return <AppShell padding={0} header={<HeaderMenuColored setHeaderHeight={setHeaderHeight} links={[
        {link: "/", label: "Learn"},
        {link: "playground", label: "Playground"},
    ]}/>} footer={<FooterSimple/>}>
        {/* don't want to have inline padding, just block */}
        <Space h={"md"}/>
        <Outlet context={headerHeight}/>
        <Space h={"md"}/>
    </AppShell>
}