export type SetScrollParamOptions = {
    axis: 'x' | 'y',
    parent: HTMLElement | null,
    distance: number,
}

export const setScrollParam = ({ axis, parent, distance }: SetScrollParamOptions) => {
    if (!parent && typeof document === 'undefined') {
        return;
    }

    const method = axis === 'y' ? 'scrollTop' : 'scrollLeft';

    if (parent) {
        // eslint-disable-next-line no-param-reassign
        parent[method] = distance;
    } else {
        const { body, documentElement } = document;

        // https://www.w3schools.com/jsref/prop_element_scrolltop.asp
        body[method] = distance;
        documentElement[method] = distance;
    }
};
