import {Button, Container, createStyles, Divider, Group, List, Notification, Paper, ScrollArea, Space, Text, TextInput, Title} from "@mantine/core";
import Subtitle from "../../components/Subtitle";
import {Form, useLoaderData, useSubmit} from "react-router-dom";
import {useForm} from "@mantine/form";
import NFAGraph from "../../components/NFAGraph";
import {convertNFAToDFA, convertRegexToNFA, DFA, DFAPath, GeneratedStrings, generateStrings, NFABranch, testString} from "../../utils/regex";
import DFAGraph from "../../components/DFAGraph";
import Note from "../../components/Note";
import ResponsiveGroup from "../../components/ResponsiveGroup";
import {IconCheck, IconX} from "@tabler/icons";
import React, {useRef, useState} from "react";

const useStyles = createStyles(theme => ({
    graphs: {
        marginBlock: theme.spacing.sm,
        borderBottom: `solid 1px ${theme.colors.gray[8]}`,

        display: "flex",
        flexDirection: "column",
        resize: "vertical",
        overflow: "auto",
        minHeight: 400,
    },
    graph: {
        flexGrow: 1,
    },

    paper: {
        backgroundColor: theme.colorScheme === "light" ? theme.colors.gray[0] : theme.colors.dark[8],
        overflow: "hidden",
    },
    paperNote: {
        backgroundColor: theme.colorScheme === "light" ? theme.white : theme.colors.dark[7]
    },
    generatedStrings: {
        height: 200,
        flexGrow: 1,
    },
    notification: {
        flexBasis: 350,
        flexShrink: .5,
    }
}))

const initialRegex = "(abc)*(d|e)"

export default function Playground() {
    const data = useLoaderData() as PlaygroundData

    const regexForm = useForm({
        validateInputOnBlur: true,
        initialValues: {
            q: data.regex,
        },
        validate: {
            q: (value: string) => {
                if (value.trim().length === 0) return "Please enter a RegEx"
                let allowMod = false, allowOr = false
                let p = 0
                for (const char of value) {
                    if (char === ' ') continue;
                    if ("*?+".includes(char)) {
                        if (!allowMod) return "'*'/'?'/'+' must be placed after a character or group"
                        allowMod = false
                    } else if (char === '|') {
                        if (!allowOr) return "'|' must be placed between sub-expressions"
                        allowMod = allowOr = false
                    } else if (char === '(') {
                        allowMod = allowOr = false
                        ++p
                    } else if (char === ')') {
                        if (!allowOr) return "Empty groups are not allowed, use ε ('$') instead"
                        if (--p < 0) return "Invalid ')' - count your brackets!"
                        allowMod = allowOr = true
                    } else if (".,{}^[]&;:@".includes(char)) return "Special characters allowed: '(', ')', '*', '|', '+' and '?'"
                    else if (/[a-zA-Z0-9$ε]/.test(char)) allowMod = allowOr = true
                    else return `Invalid character '${char}', only a-z, A-Z, 0-9, ε/$ (epsilon) allowed`
                }
                if (!allowOr) return "Invalid end of expression"
                return p !== 0 ? "You have some brackets '(' that are never closed" : null
            },
        },
    })
    const regexTestField = useRef<HTMLInputElement>(null)
    const [regexTestResult, setRegexTestResult] = useState<DFAPath | null>(null)
    const [animate, setAnimate] = useState(0)
    const submit = useSubmit()

    const {classes} = useStyles()
    return (<Container>
        <Title>Playground</Title>
        <Subtitle>Convert RegEx → NFA → DFA, test your RegEx & more</Subtitle>

        <Space h={"xl"}/>
        {/* TODO Add tabs, graph editing */}

        <Title order={2}>Convert & test your regular expression</Title>
        <Form method={"get"} onSubmit={(event) => regexForm.onSubmit(() => {
            setRegexTestResult(null)
            submit(event.currentTarget)
        })}>
            <TextInput label={"Regular expression:"} name={"q"} {...regexForm.getInputProps("q")}
                       description={<>
                           Allowed characters: a-z, A-Z, 0-9 and '$'. You can use '$' instead of 'ε' (epsilon). Spaces are ignored.<br/>
                           Minimal notation characters: '(', ')', '*' and '|'. You may also use '+' and '?'.
                       </>} placeholder={initialRegex} rightSection={
                <Button type={"submit"} fullWidth={true}>Convert!</Button>
            } rightSectionWidth={100}/>
        </Form>
        <Paper className={classes.paper} withBorder my={"sm"} p={"sm"} radius={"md"}>
            <ResponsiveGroup noWrap cutoff={"xs"} spacingHorizontal={"xs"} align={"stretch"}>
                <ScrollArea type={"auto"} className={classes.generatedStrings} offsetScrollbars={true}>
                    <Text size={"lg"}>Strings that match the given RegEx:</Text>
                    {data.generated.infiniteCycle && <Note className={classes.paperNote}>
                        The expression matches an infinite amount of strings, so this list isn't complete.
                    </Note>}
                    <List mb={-10}>
                        {data.generated.strings.map(string => <List.Item key={"key-" + string}>"{string}"</List.Item>)}
                    </List>
                </ScrollArea>
                <Divider orientation={"vertical"}/>
                <form className={classes.notification} onSubmit={event => {
                    event.preventDefault()
                    setAnimate(0)
                    setRegexTestResult(testString(regexTestField.current!.value.trim(), data.dfa))
                }}>
                    <TextInput label={"Test if a string matches the RegEx:"} name={"string"} placeholder={"YourStringHere"} rightSection={
                        <Button type={"submit"} fullWidth={true}>Test</Button>
                    } rightSectionWidth={80} ref={regexTestField} onChange={setRegexTestResult.bind(null, null)}/>
                    <Space h={"md"}/>
                    {regexTestResult != null && (
                        <Notification icon={regexTestResult.length ? <IconCheck size={22}/> : <IconX size={22}/>} disallowClose
                                      color={regexTestResult.length ? "green" : "red"}>
                            <Group position={"apart"}>
                                <Text size={"lg"} weight={"bold"} color={regexTestResult.length ? "green" : "red"}>
                                    {regexTestResult.length ? "Matches" : "Doesn't match"}
                                </Text>
                                {regexTestResult.length && <Button variant={"outline"} onClick={() => setAnimate(i => i + 1)} mb={1}>Show me</Button>}
                            </Group>
                        </Notification>
                    )}
                </form>
            </ResponsiveGroup>
        </Paper>
        <div className={classes.graphs}>
            <NFAGraph withControls height={300} className={classes.graph} title={"NFA form"} nfa={data.nfa}/>
            <Divider orientation={"horizontal"} my={"xl"}/>
            <DFAGraph withControls height={300} dfa={data.dfa} nodesDraggable className={classes.graph}
                      title={"DFA form"} animation={regexTestResult} animate={animate}/>
        </div>
        <Note>You may drag states around in the DFA form to improve visibility.</Note>
    </Container>)
}

type PlaygroundData = { regex: string, nfa: NFABranch, dfa: DFA, generated: GeneratedStrings }

function processRegex(regex: string): PlaygroundData {
    const nfa = convertRegexToNFA(regex), dfa = convertNFAToDFA(nfa)
    return {regex, nfa, dfa, generated: generateStrings(dfa)}
}

export async function PlaygroundLoader({ request }: {request: Request}) {
    console.log("loading")
    const regex = new URL(request.url).searchParams.get("q")
    return processRegex(regex ?? initialRegex)
}