// NFA Types for easy rendering (not graph form)
export type NFABranch = NFANode[] & {
    // Additional fields required by NFAGraph
    shiftUp?: number,
    width?: number,
    height?: number,
}

export type NFANode = {
    id: number,
    // Extra (down) transition
    transition?: { to: number, char?: string },
    // Character for right-transition to this node
    char?: string,
    // If this is not null, this is a split-node
    split?: NFABranch[]

    // Additional fields required by NFAGraph
    transitionSpacing?: number,
    height?: number,
}

export function convertRegexToNFA(regex: string): NFABranch {
    let nodeID = 1
    const node = (opts?: Omit<NFANode, "id">): NFANode => ({...opts, id: nodeID++})

    function parseRegex(regex: string, start: number = 0): [NFABranch, number] {
        const orNodes: NFABranch[] = []

        let nodes: NFABranch[] = [], i: number
        for (i = start; i < regex.length; ++i) {
            if (regex[i] === ' ') continue // skip whitespaces
            if (regex[i] === '(') {
                const [newNodes, end] = parseRegex(regex, i + 1)
                nodes.push(newNodes)
                i = end
            } else if (regex[i] === ')') break
            else if (regex[i] === "*") {
                const expr = nodes.pop()!
                expr[expr.length - 1].transition = {to: expr[0].id}

                const newExpr = [node(), ...expr, node()]
                newExpr[0].transition = {to: newExpr[newExpr.length - 1].id}

                nodes.push(newExpr)
            } else if (regex[i] === '+') {
                // Same as *, but without the epsilon-transition that allows skipping
                const expr = nodes.pop()!
                expr[expr.length - 1].transition = {to: expr[0].id}
                nodes.push(expr)
            } else if (regex[i] === '?') {
                const expr = nodes.pop()!
                expr[0].transition = {to: expr[expr.length - 1].id}
                nodes.push(expr)
            } else if (regex[i] === '|') {
                orNodes.push(nodes.flat())
                nodes = []
            } else {
                nodes.push([node(), node({char: regex[i] === '$' || regex[i] === 'ε' ? undefined : regex[i]})])
            }
        }

        if (orNodes.length === 0) return [nodes.flat(), i]
        if (nodes.length > 0) orNodes.push(nodes.flat())

        const mergeNode = node()
        return [[node({split: orNodes}), mergeNode], i]
    }

    const nodes = parseRegex(regex)[0]
    // Re-assign consecutive IDs to nodes
    nodeID = 1
    const idMap = new Map<number, number>()

    function reassignNodeIds(nodes: NFABranch) {
        for (const node of nodes) {
            idMap.set(node.id, nodeID)
            node.id = nodeID++
            if (node.split) node.split.forEach(branch => reassignNodeIds(branch))
        }
    }

    reassignNodeIds(nodes)

    // Also update transitions
    function updateTransitions(nodes: NFABranch) {
        for (const node of nodes) {
            if (node.transition) node.transition.to = idMap.get(node.transition.to)!
            if (node.split) node.split.forEach(branch => updateTransitions(branch))
        }
    }

    updateTransitions(nodes)
    return nodes
}

export type DFANode = { label: string, type?: "input-node" | "output-node" | "input-node output-node", transitions: DFATransition[] }
export type DFATransition = { to: string, char: string }
export type DFA = Map<string, DFANode>

export function convertNFAToDFA(nfa: NFABranch): DFA {
    // Convert DFA to graph form
    type Node = {
        id: number,
        transitions: Transition[],
    }
    type Transition = {
        to: Node,
        char?: string,
    }

    // First, map all nodes to new objects
    const idMap = new Map<number, Node>()

    function mapBranch(branch: NFABranch) {
        for (const node of branch) {
            idMap.set(node.id, {id: node.id, transitions: []})
            if (node.split) for (const branch of node.split) mapBranch(branch)
        }
    }

    mapBranch(nfa)

    // Next, map transitions
    function convertBranch(branch: NFABranch) {
        for (let i = 0; i < branch.length; i++) {
            const nfaNode = branch[i], next = branch[i + 1]
            const newNode = idMap.get(nfaNode.id)!

            if (nfaNode.transition) newNode.transitions.push({to: idMap.get(nfaNode.transition.to)!, char: nfaNode.transition.char})
            if (nfaNode.split) for (const branch of nfaNode.split) {
                newNode.transitions.push({to: idMap.get(branch[0].id)!})
                idMap.get(branch[branch.length - 1].id)!.transitions.push({to: idMap.get(next.id)!})
                convertBranch(branch)
            } else if (next) newNode.transitions.push({to: idMap.get(next.id)!, char: next.char})
        }
    }

    convertBranch(nfa)

    type ConvertNode = {
        // All NFA node IDs, sorted and joined with comma separator
        label: string,
        nfaNodes: Set<Node>,
        transitions: { to: Node, char: string }[]
    }

    // Function to expand (follow all epsilon-transitions) of a given NFA Node
    // Returns the expanded node & all non-epsilon transitions it has
    function expand(node: Node, vis: Set<Node> = new Set(), transitions: { to: Node, char: string }[] = []): ConvertNode {
        // Mark node as visited
        vis.add(node);
        for (const tr of node.transitions) {
            if (tr.char == null) {
                // Don't visit the same node twice
                if (!vis.has(tr.to)) expand(tr.to, vis, transitions);
            } else transitions.push(tr as any) // char is guaranteed not null here
        }
        return {
            label: [...vis].map(node => node.id).sort((a, b) => a - b).join(","),
            nfaNodes: vis,
            transitions: transitions
        }
    }

    const firstConvertNode = expand(idMap.get(nfa[0].id)!)
    const finalNfaNode = idMap.get(nfa[nfa.length - 1].id)!

    const DFA = new Map<string, DFANode>()
    DFA.set(firstConvertNode.label, {
        label: firstConvertNode.label,
        type: firstConvertNode.nfaNodes.has(finalNfaNode) ? "input-node output-node" : "input-node",
        transitions: [],
    })
    const queue = [firstConvertNode]

    while (queue.length !== 0) {
        const convertNode = queue.shift()!
        const node = DFA.get(convertNode.label)!
        // Check all transitions
        for (const tr of convertNode.transitions) {
            const dest = expand(tr.to)
            if (!DFA.has(dest.label)) {
                DFA.set(dest.label, {label: dest.label, type: dest.nfaNodes.has(finalNfaNode) ? "output-node" : undefined, transitions: []})
                queue.push(dest)
            }
            // Remember the transition
            node.transitions.push({to: dest.label, char: tr.char})
        }
    }

    return DFA
}

export function convertRegexToDFA(regex: string) {
    return convertNFAToDFA(convertRegexToNFA(regex))
}

export type GeneratedStrings = { strings: string[], infiniteCycle: boolean }

export function generateStrings(dfa: DFA): GeneratedStrings {
    const finalNodes = new Set<string>()
    // If we visit a node more than number of nodes times, there's an infinite cycle
    const visits = new Map<string, number>()
    dfa.forEach(node => {
        if (node.type?.includes("output-node")) finalNodes.add(node.label)
        visits.set(node.label, 0)
    })

    const strings: string[] = []
    let infiniteCycle = false

    function visitNode(node: string, text: string) {
        if (finalNodes.has(node)) strings.push(text)
        const visitCount = visits.get(node)!
        if (visitCount >= dfa.size) {
            infiniteCycle = true
            return
        } else visits.set(node, visitCount + 1)

        for (const tr of dfa.get(node)!.transitions) visitNode(tr.to, text + tr.char)
    }

    const [initial] = dfa.keys()
    visitNode(initial, "")

    return {strings, infiniteCycle}
}

export type DFAPath = DFANode[]

/**
 * Tests a string against the DFA-form of a regex
 * @param string String to be tested
 * @param dfa The regex in DFA graph form
 * @return DFAPath The path taken through the graph
 */
export function testString(string: string, dfa: DFA) {
    let path: DFAPath = []

    function visitNode(node: DFANode, index: number) {
        if (node.type?.includes("output-node") && index === string.length) {
            path.unshift(node)
            return true
        }
        if (index >= string.length) return false

        for (const tr of dfa.get(node.label)!.transitions)
            if (tr.char === string[index] && visitNode(dfa.get(tr.to)!, index + 1)) {
                path.unshift(node)
                return true
            }
        return false
    }

    const [initial] = dfa.values()
    visitNode(initial, 0)

    return path
}